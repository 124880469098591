@import "~@/styles/variables/variables.scss";


































































































































































.type-manage-header {
	@include base-button($width: 120px);
}
.table-show {
	flex-direction: column;
	padding: 20px 24px 0 24px;
	height: 0;
}
