@import "~@/styles/variables/variables.scss";













































































































































































































































































































































































.device-type-ruleForm {
	.title {
		width: 100%;
		color: #ababab;
	}
	.split-block {
		display: flex;
		flex-wrap: wrap;
	}
	.split-line {
		margin: 20px 0;
		border-bottom: 1px dashed #cccccc;
	}
	::v-deep {
		.el-form-item {
			padding-top: 20px;
			margin-bottom: 0;
			margin-right: 30px;
			width: calc(calc(100% - 60px) / 3);
		}
		.el-form-item:nth-of-type(3n) {
			margin-right: 0;
		}
		.el-form-item__label {
			line-height: 21px;
			height: auto;
			font-weight: 600;
			color: #222;
		}
	}
}
